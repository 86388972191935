import { Modal, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { services } from "../config";
import { toast } from "react-toastify";
import LoginDialog from "./LoginDialog";
import { useState } from "react";
import { nanoid } from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";

const VendorCat = [
  { label: "Service", value: "Service" },
  { label: "Goods", value: "Goods" },
];

export default function NewVendorModal({
  showCreateNewVendorModal,
  setShowCreateNewVendorModal,
  setSelectedVendor,
}) {
  const { backendUrl } = useAuth();
  const formik = useFormik({
    initialValues: {
      Vendor_ID: String(Date.now()),
      CompanyName: "",
      ContactName: "",
      ContactTitle: "",
      Address: "",
      Phone: "",
      Email: "",
      City: "",
      DateCreated: moment(),
      Vendor_Cat: "",
    },
    validationSchema: yup.object().shape({
      Vendor_ID: yup.string().required("required"),
      CompanyName: yup.string().required("required"),
      Vendor_Cat: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const { DateCreated } = values;
      if (typeof DateCreated === "string") {
        formik.setFieldError("DateCreated", "Invalid date");
      }

      submit({
        ...values,
        DateCreated: DateCreated.format(),
      });
    },
  });

  const createVendor = async (payload) => {
    let response = await fetch(`${backendUrl}/api/vendors/create`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createVendorMutation = useMutation((payload) => createVendor(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);

      if (setSelectedVendor) setSelectedVendor(data.vendor);
      formik.resetForm();
      setShowCreateNewVendorModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const submit = (payload) => {
    createVendorMutation.mutate(payload);
  };

  return (
    <Modal
      show={showCreateNewVendorModal}
      onHide={() => setShowCreateNewVendorModal(false)}
      dialogClassName="item-select-modal edit"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Vendor Information</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className=" pb-2"
          autoComplete="off"
        >
          <div className="">
            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-2">Vendor ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="xxxxxx"
                name="Vendor_ID"
                value={formik.values.Vendor_ID}
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                name="CompanyName"
                value={formik.values.CompanyName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.CompanyName && !!formik.errors.CompanyName
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.CompanyName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Contact Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Contact Name"
                name="ContactName"
                value={formik.values.ContactName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactTitle && !!formik.errors.ContactTitle
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactTitle}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Contact Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Contact Title"
                name="ContactTitle"
                value={formik.values.ContactTitle}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactTitle && !!formik.errors.ContactTitle
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactTitle}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="Phone"
                  value={formik.values.Phone}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Phone && !!formik.errors.Phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Phone}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3">
                <Form.Label>Vendor Category</Form.Label>
                <Select
                  classNamePrefix="form-select"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  placeholder="Category"
                  isSearchable={false}
                  value={VendorCat.find(
                    (d) => d.value === formik.values.Vendor_Cat
                  )}
                  options={VendorCat}
                  onChange={(selected) => {
                    formik.setFieldValue("Vendor_Cat", selected.value);
                  }}
                />
                {formik.touched.Vendor_Cat && !!formik.errors.Vendor_Cat ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Vendor_Cat}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-6 mb-3">
                <Form.Label htmlFor="DateCreated">Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="DateCreated"
                  inputProps={{
                    className: `date-input form-control ${
                      formik.touched.DateCreated && !!formik.errors.DateCreated
                        ? "is-invalid"
                        : ""
                    }`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.DateCreated}
                  onChange={(date) => {
                    formik.setFieldValue("DateCreated", date, true);
                  }}
                  onBlur={() => formik.setFieldTouched("BOB", true)}
                />
                {formik.touched.DateCreated && !!formik.errors.DateCreated ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.DateCreated}
                  </span>
                ) : null}
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Vendor address"
                name="Address"
                rows={5}
                value={formik.values.Address}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Address && !!formik.errors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                name="City"
                value={formik.values.City}
                onChange={formik.handleChange}
                isInvalid={formik.touched.City && !!formik.errors.City}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.City}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          className="w-100 submit-btn mt-3 py-2"
          disabled={createVendorMutation.isLoading}
          onClick={() => formik.submitForm()}
        >
          {createVendorMutation.isLoading ? "Please wait..." : "Create Vendor"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
