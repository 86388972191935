import { Table, InputGroup, Button, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterCollapseIcon,
  FilterTwoIcon,
  // PrintIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import { useEffect, useState } from "react";
// import DateRangePicker from "../../utils/DateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
// import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
// import { services } from "../../../config";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  // defaultSelectValue,
  paginationOptions,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
// import BatchesModal from ".././BatchesModal";
import ConfirmDialog from "../ConfirmDialogue";
import NoTableItem from "../utils/NoTableItem";
import { fetchActionsUtil } from "../../utils/helpers";
import { CreateProductLogModal } from "./CreateProductLogModal";

export default function PlantProductLog() {
  const { backendUrl } = useAuth();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCreateNew, setShowCreateNew] = useState(false);

  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  useScrollTop();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const { data = { count: 0 }, refetch, isFetching, isSuccess } = useQuery(
    ["PRODUCT_LOG", { queryParams, plantLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/production/product-log/${
          plantLocal.PLID
        }?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter Item Code"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          //   content={() => (
                          //     <WarehouseItemTable
                          //       handleSelectedItem={handleSelectedItem}
                          //     />
                          //   )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Inventory
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <button
                    onClick={() => setShowCreateNew(true)}
                    className="btn bg-primary text-white"
                  >
                    Enter New Production
                  </button>

                  {/*  <button className="btn print">
                Print <PrintIcon />
              </button>
              <button className="btn print">
                Print <ExportIcon />
              </button> */}
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>QTY</th>
                      <th>Unit Cost</th>
                      <th>Amount</th>
                      <th>Issue To</th>
                      <th>Post time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.ProductionLog?.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el?.Bar_Code}</td>
                        <td>{el?.Item_Name}</td>
                        <td className="p-cursor" title={el.Quantity}>
                          {el.Quantity}
                        </td>
                        <td>
                          {currency(el?.UnitCost, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el?.Amount, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {el?.Requester.toLowerCase() === "Sales".toLowerCase()
                            ? "Sales"
                            : el?.Issued_Name?.W_name}
                        </td>
                        <td>
                          {format(new Date(el?.Date_Log), "dd MMM, yyyy")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {data?.ProductionLog?.length < 1 ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                  <p className="text-center">{queryParams?.customerName}</p>
                </div>
              ) : (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              )}
            </div>
          </div>
          <CreateProductLogModal
            onHide={() => setShowCreateNew(false)}
            show={showCreateNew}
            refetchMain={refetch}
          />
        </div>
      </main>
    </section>
  );
}
